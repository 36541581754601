import React, { useState, useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { BsChevronUp } from "react-icons/bs";
import FAQData from "../data/FAQData.json";

const FAQSaperated = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const location = useLocation();
  const { selectedCategory } = location.state || {};

  // Memoized filtered questions for better performance
  const filteredQuestions = useMemo(() => FAQData[selectedCategory] || [], [selectedCategory]);

  // Efficient toggle function using useCallback
  const toggleAnswer = useCallback(
    (index) => {
      setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    },
    [] // Dependency array is empty to ensure the function is memoized
  );

  return (
    <div>
      {/* Header Section */}
      <div className="relative h-[20vh] bg-[#00c3aa] font-['Albert_Sans'] md:mb-20">
        <div className="absolute lg:left-[30vw] left-[20vw] lg:top-[12vh] top-[15vh] flex justify-center items-center text-base lg:text-3xl font-bold uppercase w-[60vw] h-[10vh] lg:w-[40vw] lg:h-[20vh] bg-white border-2 border-[#00c3aa]">
          <h1>{selectedCategory}</h1>
        </div>
      </div>

      {/* FAQ Section */}
      <div className="min-h-screen py-10 pt-20">
        <div className="max-w-5xl mx-auto bg-white rounded-md p-6">
          {/* FAQ Questions */}
          {filteredQuestions.length > 0 ? (
            filteredQuestions.map((item, index) => (
              <div
                key={item.id}
                className={`pb-4 cursor-pointer mb-4 ${
                  activeIndex === index ? "border-x-2 border-b-2 border-[#00c3aa]" : ""
                }`}
                onClick={() => toggleAnswer(index)}
              >
                {/* Question */}
                <div
                  className={`flex justify-between items-center border-b-2 border-[#00c3aa] p-3 px-10 ${
                    activeIndex === index ? "bg-[#00c3aa] text-white" : ""
                  }`}
                >
                  <p className="font-medium text-xl">
                    <span>{item.id}. </span>
                    {item.question}
                  </p>
                  <span
                    className={`text-[#011e41] font-bold transition-transform ${
                      activeIndex === index ? "rotate-180" : ""
                    }`}
                  >
                    <BsChevronUp />
                  </span>
                </div>

                {/* Answer */}
                {activeIndex === index && (
                  <div className="mt-2 text-[#011e41] text-base px-10 py-5">
                    {item.answer}
                  </div>
                )}
              </div>
            ))
          ) : (
            <p className="text-gray-600">No FAQs available for this category.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default FAQSaperated;
