import { useNavigate } from "react-router-dom";
import VisaCard from "../component/VisaCard";
import SearchComponent from "../component/searchcomponent";

import schengenVisa from "../assets/Images/services/Schengen Icon-01.svg";
import usVisa from "../assets/Images/services/US Icon-01.svg";
import ukVisa from "../assets/Images/services/UK Icon-01.svg";
import australiaVisa from "../assets/Images/services/Australia Icon-01.svg";
import nzVisa from "../assets/Images/services/New Zealand-01.svg";
import canadaVisa from "../assets/Images/services/Canada Icon-01.svg";
import visaExtensions from "../assets/Images/services/Visa Icon-01.svg";

const FAQPage = () => {
  const navigate = useNavigate();

  // Static data for FAQ cards
  const data = [
    {
      title: "Schengen Visa",
      details: {
        1: "What services does The Flying Panda offer",
        2: "How do I book a Schengen visa appointment",
        3: "What documents do I need for a Schengen visa application",
      },
      image: schengenVisa,
      path: "/schengen-visa",
    },
    {
      title: "US Visa",
      details: {
        1: "How can I apply for a US visa from the UK",
        2: "What types of US visas can I apply for from the UK",
        3: "How long does it take to get a US visa from the UK",
      },
      image: usVisa,
      path: "/",
    },
    {
      title: "UK Visa from India",
      details: {
        1: "How do I apply for a UK visa from India",
        2: "What documents do I need for a UK visa from India",
        3: "How long does it take to get a UK visa from India",
      },
      image: ukVisa,
      path: "/",
    },
    {
      title: "Australia Visa",
      details: {
        1: "How do I apply for an Australia visa",
        2: "What are the types of visas for Australia",
        3: "How long does it take to get an Australia visa",
      },
      image: australiaVisa,
      path: "/",
    },
    {
      title: "New Zealand Visa",
      details: {
        1: "How do I apply for a New Zealand visa",
        2: "What types of visas are available for New Zealand",
        3: "How long does it take to process a New Zealand visa",
      },
      image: nzVisa,
      path: "/",
    },
    {
      title: "Canada Visa",
      details: {
        1: "How can I apply for a Canada visa",
        2: "What are the types of Canada visas available",
        3: "How long does it take to get a Canada visa",
      },
      image: canadaVisa,
      path: "/canada-visa",
    },
    {
      title: "Visa Extensions",
      details: {
        1: "Can I extend my visa for Australia, New Zealand, or Canada",
      },
      image: visaExtensions,
      path: "/visa-extensions",
    },
  ];

  // Navigation handler
  const handleSeeAll = (title) => {
    navigate("/saperatedfaq", { state: { selectedCategory: title } });
  };

  return (
    <div className="flex flex-col items-center p-6 font-['Albert_Sans']">
      {/* Header Section */}
      <h1 className="text-3xl lg:text-6xl font-bold text-center text-[#011e41] mt-10">
        How can we help you?
      </h1>
      <p className="text-center text-[#011e41] font-base lg:text-xl mt-6 lg:w-1/2">
        Here are a few of the questions we get the most. If you don’t see what’s
        on your mind, reach out to us anytime on phone, chat, or email.
      </p>

      {/* Search Component */}
      <SearchComponent />
      <p className="text-center text-gray-500 mt-2 lg:mt-4 text-xs lg:text-base">
        You can also browse the topics below to find what you are looking for.
      </p>

      {/* Visa Cards */}
      <div className="flex flex-wrap mt-8 justify-center items-center gap-10">
        {data.map((item, index) => (
          <div key={index} className="flex-none w-full sm:w-[48%] lg:w-[22%]">
            <VisaCard
              title={item.title}
              details={item.details}
              image={item.image}
              path={item.path}
              onSeeAll={() => handleSeeAll(item.title)} // Pass title to handleSeeAll
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQPage;
