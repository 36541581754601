import React, { useRef, useState, useEffect, Suspense, lazy } from "react";
import ContactSection from "../sections/ContactSection";
import PricingSections from "../sections/PricingSection";
import Counter from "../component/counter";
import { FiArrowRight } from "react-icons/fi";
import { Shimmer } from "../component/Shimmer";

// Lazy-loaded components
const Testimonials = lazy(() => import("../sections/Testimonials"));
const Whatwedo = lazy(() => import("../sections/WhatWeDo"));
const WhyChooseUs = lazy(() => import("../sections/Whychooseus"));

function Homepage() {
  const aboutRef = useRef(null);
  const pricingRef = useRef(null);
  const contactRef = useRef(null);
  const testimonials = useRef(null);
  const servicesref = useRef(null);

  const numbersRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.3 }
    );

    if (numbersRef.current) observer.observe(numbersRef.current);

    return () => observer.disconnect();
  }, []);

  return (
    <div className="App font-['Albert_Sans']">
      <section
        id="about"
        ref={aboutRef}
        className="relative flex flex-col md:flex-row items-center bg-gradient-to-b from-[#D9D9D9] to-[#CECECE] lg:min-h-screen md:py-20"
      >
        <div className="md:w-9/12 lg:w-7/12 space-y-4 text-center md:text-left flex justify-end">
          <div className="md:w-11/12">
            <h1 className="mt-6 md:mt-0 text-[35px] md:text-6xl lg:text-[75px] font-bold text-[#011e41] leading-[1.2]">
              Travel Boldly: Your Partner in Seamless Visa Solutions
            </h1>
            <p className="px-6 md:px-0 text-[12px] md:text-[24px] text-[#011e41] mt-5 md:mt-[36px] md:w-8/12">
              Enjoy Hassle-Free Visa Processing Every Step of the Way!
            </p>
            <a
              className="mt-5 md:mt-[50px] inline-flex items-center text-[#011e41] pb-6 md:pb-0"
              href="https://wa.me/447553768098"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="border-b border-[#011e41] text-[16px] md:text-[20px] ">
                Know More
              </span>
              <span className="ml-2 w-5 h-5 md:w-8 md:h-8 bg-[#011e41] text-white flex items-center justify-center rounded md:rounded-md">
                <FiArrowRight className="w-3 md:w-6 md:h-6 text-white" />
              </span>
            </a>
          </div>
        </div>

        {/* Optimized Image Section */}
        <div className="md:absolute md:left-[390px] lg:left-[520px] md:bottom-[0px] xl:left-[650px] lg:bottom-[0px] 3xl:left-[800px] md:w-6/12 flex justify-center md:justify-end mb-0">
          <Suspense fallback={<Shimmer />}>
            <picture>
              <source
                srcSet={require("../assets/Images/Woman_Image_Optimized.webp")}
                type="image/webp"
              />
              <source
                srcSet={require("../assets/Images/Woman_Image_Optimized.webp")}
                type="image/jpeg"
              />
              <img
                src={require("../assets/Images/Woman_Image_Optimized.webp")}
                alt="Traveler with passport"
                className="rounded-lg"
                loading="lazy"
                decoding="async"
              />
            </picture>
          </Suspense>
        </div>
      </section>

      {/* Rest of the content sections */}
      <div id="pricingsection" ref={pricingRef}>
        <PricingSections />
      </div>

      <div id="whatwedo">
        <Suspense fallback={<Shimmer />}>
          <Whatwedo />
        </Suspense>
      </div>

      {/* Numbers Section */}
      <div className="md:flex justify-center">
        <section ref={numbersRef} className="mb-10 lg:w-11/12">
          <div className="md:flex justify-between lg:gap-6">
            <div className="bg-white w-full py-6 lg:py-0 lg:p-6 rounded-lg text-center bg-emerald-100 ">
              <h3 className="text-[70px] font-bold text-[#00c6ad]">
                <Counter target={2000} isVisible={isVisible} />+
              </h3>
              <p className="text-[20px] mt-2 pr-5">Visa Facilitated</p>
            </div>
            <div className="bg-white w-full py-6 lg:py-0 lg:p-6 rounded-lg text-center bg-emerald-100  ">
              <h3 className="text-[70px] font-bold text-[#00c6ad]">
                <Counter target={99} isVisible={isVisible} />%
              </h3>
              <p className="text-[20px] mt-2 pr-5">Success Rate</p>
            </div>
            <div className="bg-white w-full py-6 lg:py-0 lg:p-6 rounded-lg text-center bg-emerald-100  ">
              <h3 className="text-[70px] font-bold text-[#00c6ad]">
                <Counter target={1500} isVisible={isVisible} />+
              </h3>
              <p className="text-[20px] mt-2 pr-5">Happy Customers</p>
            </div>
            <div className="bg-white w-full py-6 lg:py-0 lg:p-6 rounded-lg text-center bg-emerald-100 ">
              <h3 className="text-[70px] font-bold text-[#00c6ad]">
                <Counter target={50} isVisible={isVisible} />+
              </h3>
              <p className="text-[20px] mt-2 pr-5">Nationalities Served</p>
            </div>
          </div>
        </section>
      </div>

      {/* <div id="testimonials" ref={testimonials}>
        <Suspense fallback={<Shimmer />}>
          <Testimonials />
        </Suspense>
      </div> */}

      <div id="Services" ref={servicesref}>
        <Suspense fallback={<Shimmer />}>
          <WhyChooseUs />
        </Suspense>
      </div>

      <div id="contact" ref={contactRef}>
        <ContactSection />
      </div>
    </div>
  );
}

export default Homepage;
