import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaChevronDown } from "react-icons/fa";
import { Listbox } from "@headlessui/react";
import SelectPopup from "../modal/SelectPopup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import countriesData from '../data/countries.json';

const PricingSection = () => {
  const [selection, setSelection] = useState({
    from: "Select Country",
    to: "",
  });

  const [passportFromCountry, setPassportFromCountry] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("Select Country");
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [validation, setValidation] = useState(false);
  const [isModalOpenDate, setIsModalOpenDate] = useState(false);
  const [isModalOpenPassport, setIsModalOpenPassport] = useState(false);
  const [isModalOpenDestination, setIsModalOpenDestination] = useState(false);
  console.log(dateRange, "from data");

  const countryOptions = {
    UK: [
      {
        name: "Select Destination",
        flagUrl:
          "https://img.freepik.com/premium-photo/white-background-with-few-clouds-background_994023-435278.jpg",
      },
      {
        name: "Austria",
        flagUrl:
          "https://upload.wikimedia.org/wikipedia/commons/4/41/Flag_of_Austria.svg",
      },
      {
        name: "Bulgaria",
        flagUrl:
          "https://upload.wikimedia.org/wikipedia/commons/9/9a/Flag_of_Bulgaria.svg",
      },
      {
        name: "Belgium",
        flagUrl:
          "https://upload.wikimedia.org/wikipedia/commons/6/65/Flag_of_Belgium.svg",
      },
      {
        name: "Czechia",
        flagUrl:
          "https://upload.wikimedia.org/wikipedia/commons/c/cb/Flag_of_the_Czech_Republic.svg",
      },
      {
        name: "Croatia",
        flagUrl:
          "https://upload.wikimedia.org/wikipedia/commons/1/1b/Flag_of_Croatia.svg",
      },
      {
        name: "Denmark",
        flagUrl:
          "https://upload.wikimedia.org/wikipedia/commons/9/9c/Flag_of_Denmark.svg",
      },
      {
        name: "Estonia",
        flagUrl:
          "https://upload.wikimedia.org/wikipedia/commons/8/8f/Flag_of_Estonia.svg",
      },
      {
        name: "Finland",
        flagUrl:
          "https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Finland.svg",
      },
      {
        name: "France",
        flagUrl:
          "https://upload.wikimedia.org/wikipedia/commons/c/c3/Flag_of_France.svg",
      },
      {
        name: "Germany",
        flagUrl:
          "https://upload.wikimedia.org/wikipedia/en/b/ba/Flag_of_Germany.svg",
      },
      {
        name: "Hungary",
        flagUrl:
          "https://upload.wikimedia.org/wikipedia/commons/c/c1/Flag_of_Hungary.svg",
      },
      {
        name: "Iceland",
        flagUrl:
          "https://upload.wikimedia.org/wikipedia/commons/c/ce/Flag_of_Iceland.svg",
      },
      {
        name: "Italy",
        flagUrl:
          "https://upload.wikimedia.org/wikipedia/en/0/03/Flag_of_Italy.svg",
      },
      {
        name: "Latvia",
        flagUrl:
          "https://upload.wikimedia.org/wikipedia/commons/8/84/Flag_of_Latvia.svg",
      },
      {
        name: "Lithuania",
        flagUrl:
          "https://upload.wikimedia.org/wikipedia/commons/1/11/Flag_of_Lithuania.svg",
      },
      {
        name: "Malta",
        flagUrl:
          "https://upload.wikimedia.org/wikipedia/commons/7/73/Flag_of_Malta.svg",
      },
      {
        name: "Netherlands",
        flagUrl:
          "https://upload.wikimedia.org/wikipedia/commons/2/20/Flag_of_the_Netherlands.svg",
      },
      {
        name: "Norway",
        flagUrl:
          "https://upload.wikimedia.org/wikipedia/commons/d/d9/Flag_of_Norway.svg",
      },
      {
        name: "Portugal",
        flagUrl:
          "https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_Portugal.svg",
      },
      {
        name: "Slovakia",
        flagUrl:
          "https://upload.wikimedia.org/wikipedia/commons/e/e6/Flag_of_Slovakia.svg",
      },
      {
        name: "Slovenia",
        flagUrl:
          "https://upload.wikimedia.org/wikipedia/commons/f/f0/Flag_of_Slovenia.svg",
      },
      {
        name: "Spain",
        flagUrl:
          "https://upload.wikimedia.org/wikipedia/commons/9/9a/Flag_of_Spain.svg",
      },
      {
        name: "Sweden",
        flagUrl:
          "https://upload.wikimedia.org/wikipedia/commons/4/4c/Flag_of_Sweden.svg",
      },
      {
        name: "Switzerland",
        flagUrl:
          "https://upload.wikimedia.org/wikipedia/commons/f/f3/Flag_of_Switzerland.svg",
      },
    ],
    Ireland: [
      {
        name: "Select Destination",
        flagUrl:
          "https://img.freepik.com/premium-photo/white-background-with-few-clouds-background_994023-435278.jpg",
      },
      {
        name: "Austria",
        flagUrl:
          "https://upload.wikimedia.org/wikipedia/commons/4/41/Flag_of_Austria.svg",
      },
      {
        name: "Switzerland",
        flagUrl:
          "https://upload.wikimedia.org/wikipedia/commons/f/f3/Flag_of_Switzerland.svg",
      },
      {
        name: "Liechtenstein",
        flagUrl:
          "https://upload.wikimedia.org/wikipedia/commons/4/47/Flag_of_Liechtenstein.svg",
      },
      {
        name: "Slovenia",
        flagUrl:
          "https://upload.wikimedia.org/wikipedia/commons/f/f0/Flag_of_Slovenia.svg",
      },
      {
        name: "Germany",
        flagUrl:
          "https://upload.wikimedia.org/wikipedia/en/b/ba/Flag_of_Germany.svg",
      },
      {
        name: "Finland",
        flagUrl:
          "https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Finland.svg",
      },
      {
        name: "Denmark",
        flagUrl:
          "https://upload.wikimedia.org/wikipedia/commons/9/9c/Flag_of_Denmark.svg",
      },
      {
        name: "Croatia",
        flagUrl:
          "https://upload.wikimedia.org/wikipedia/commons/1/1b/Flag_of_Croatia.svg",
      },
      {
        name: "Norway",
        flagUrl:
          "https://upload.wikimedia.org/wikipedia/commons/d/d9/Flag_of_Norway.svg",
      },
      {
        name: "Sweden",
        flagUrl:
          "https://upload.wikimedia.org/wikipedia/commons/4/4c/Flag_of_Sweden.svg",
      },
      {
        name: "Iceland",
        flagUrl:
          "https://upload.wikimedia.org/wikipedia/commons/c/ce/Flag_of_Iceland.svg",
      },
      {
        name: "Spain",
        flagUrl:
          "https://upload.wikimedia.org/wikipedia/commons/9/9a/Flag_of_Spain.svg",
      },
      {
        name: "Belgium",
        flagUrl:
          "https://upload.wikimedia.org/wikipedia/commons/6/65/Flag_of_Belgium.svg",
      },
    ],
  };

  // const countryOptions = {
  //   "select country": [
  //     {
  //       name: "select your Destination",
  //       flagUrl:
  //         "https://img.freepik.com/premium-photo/white-background-with-few-clouds-background_994023-435278.jpg",
  //     },
  //   ],
  //   Ireland: [
  //     {
  //       name: "select your Destination",
  //       flagUrl:
  //         "https://img.freepik.com/premium-photo/white-background-with-few-clouds-background_994023-435278.jpg",
  //     },
  //     {
  //       name: "Austria",
  //       flagUrl:
  //         "https://upload.wikimedia.org/wikipedia/commons/4/41/Flag_of_Austria.svg",
  //     },
  //     {
  //       name: "France",
  //       flagUrl:
  //         "https://upload.wikimedia.org/wikipedia/en/c/c3/Flag_of_France.svg",
  //     },
  //     {
  //       name: "Germany",
  //       flagUrl:
  //         "https://upload.wikimedia.org/wikipedia/en/b/ba/Flag_of_Germany.svg",
  //     },
  //     {
  //       name: "Italy",
  //       flagUrl:
  //         "https://upload.wikimedia.org/wikipedia/en/0/03/Flag_of_Italy.svg",
  //     },
  //     {
  //       name: "Spain",
  //       flagUrl:
  //         "https://upload.wikimedia.org/wikipedia/en/9/9a/Flag_of_Spain.svg",
  //     },
  //     {
  //       name: "UK",
  //       flagUrl:
  //         "https://upload.wikimedia.org/wikipedia/en/a/ae/Flag_of_the_United_Kingdom.svg",
  //     },
  //   ],
  //   UK: [
  //     {
  //       name: "select your Destination",
  //       flagUrl:
  //         "https://img.freepik.com/premium-photo/white-background-with-few-clouds-background_994023-435278.jpg",
  //     },
  //     {
  //       name: "Ireland",
  //       flagUrl:
  //         "https://upload.wikimedia.org/wikipedia/commons/4/45/Flag_of_Ireland.svg",
  //     },
  //     {
  //       name: "Austria",
  //       flagUrl:
  //         "https://upload.wikimedia.org/wikipedia/commons/4/41/Flag_of_Austria.svg",
  //     },
  //     {
  //       name: "France",
  //       flagUrl:
  //         "https://upload.wikimedia.org/wikipedia/en/c/c3/Flag_of_France.svg",
  //     },
  //     {
  //       name: "Germany",
  //       flagUrl:
  //         "https://upload.wikimedia.org/wikipedia/en/b/ba/Flag_of_Germany.svg",
  //     },
  //     {
  //       name: "Italy",
  //       flagUrl:
  //         "https://upload.wikimedia.org/wikipedia/en/0/03/Flag_of_Italy.svg",
  //     },
  //     {
  //       name: "Spain",
  //       flagUrl:
  //         "https://upload.wikimedia.org/wikipedia/en/9/9a/Flag_of_Spain.svg",
  //     },
  //   ],
  // };

  const services = [
    {
      title: "Standard",
      price: "£75",
      upfront: "£40",
      features: [
        { text: "Visa Appointment Booking", included: true },
        { text: "Application Form Filing", included: false },
        { text: "Travel & Hotel Booking Assistance", included: false },
        { text: "Tailored Multi-Entry Visa Docs", included: false },
        { text: "Pre-Submission Application Quality Checks", included: false },
        { text: "Dedicated POC", included: false },
        { text: "Travel Insurance", included: false },
        { text: "Reserved Itineraries (Upto 2 Trips)", included: false },
        { text: "Printing & Mailing Visa Docs", included: false },
      ],
      buttonClass: "bg-green-500",
    },
    {
      title: "Comfort",
      price: "£125",
      upfront: "£40",
      popular: true,
      features: [
        { text: "Visa Appointment Booking", included: true },
        { text: "Application Form Filing", included: true },
        { text: "Travel & Hotel Booking Assistance", included: true },
        { text: "Tailored Multi-Entry Visa Docs", included: true },
        { text: "Pre-Submission Application Quality Checks", included: true },
        { text: "Dedicated POC", included: true },
        { text: "Travel Insurance Quotes", included: true },
        { text: "Reserved Itineraries (Upto 2 Trips)", included: false },
        { text: "Printing & Mailing Visa Docs", included: false },
      ],
      buttonClass: "bg-pink-500",
    },
    {
      title: "Classic",
      price: "£275",
      upfront: "£90",
      recommended: true,
      features: [
        { text: "Visa Appointment Booking", included: true },
        { text: "Application Form Filing", included: true },
        { text: "Travel & Hotel Booking Assistance", included: true },
        { text: "Tailored Multi-Entry Visa Docs", included: true },
        { text: "Pre-Submission Application Quality Checks", included: true },
        { text: "Dedicated POC", included: true },
        { text: "Comprehensive Annual Travel Insurance", included: true },
        { text: "Reserved Itineraries (Upto 2 Trips)", included: true },
        { text: "Printing & Mailing Visa Docs", included: true },
      ],
      buttonClass: "bg-blue-800",
    },
  ];

  const ironlandservices = [
    {
      title: "Standard",
      price: "€100",
      upfront: "€40",
      popular: false,
      features: [
        { text: "Visa Appointment Booking", included: true },
        { text: "Application Form Filing", included: false },
        { text: "Travel & Hotel Booking Assistance", included: false },
        { text: "Tailored Multi-Entry Visa Docs", included: false },
        { text: "Pre-Submission Application Quality Checks", included: false },
        { text: "Dedicated POC", included: false },
        {text: "Travel Insurance", included:false},
        { text: "Reserved Itineraries (Upto 2 Trips)", included: false },
        { text: "Printing & Mailing Visa Docs", included: false },
      ],
      buttonClass: "bg-green-500",
    },
    {
      title: "Comfort",
      price: "€150",
      upfront: "€40",
      popular: true,
      features: [
        { text: "Visa Appointment Booking", included: true },
        { text: "Application Form Filing", included: true },
        { text: "Travel & Hotel Booking Assistance", included: true },
        { text: "Tailored Multi-Entry Visa Docs", included: true },
        { text: "Pre-Submission Application Quality Checks", included: true },
        { text: "Dedicated POC", included: true },
        {text: "Travel Insurance", included:false},
        { text: "Reserved Itineraries (Upto 2 Trips)", included: false },
        { text: "Printing & Mailing Visa Docs", included: false },
      ],
      buttonClass: "bg-pink-500",
    },
    {
      title: "Classic",
      price: "€300",
      upfront: "€90",
      popular: true,
      features: [
        { text: "Visa Appointment Booking", included: true },
        { text: "Application Form Filing", included: true },
        { text: "Travel & Hotel Booking Assistance", included: true },
        { text: "Tailored Multi-Entry Visa Docs", included: true },
        { text: "Pre-Submission Application Quality Checks", included: true },
        { text: "Dedicated POC", included: true },
        {text: "Travel Insurance", included:true},
        { text: "Reserved Itineraries (Upto 2 Trips)", included: true },
        { text: "Printing & Mailing Visa Docs", included: true },
      ],
      buttonClass: "bg-pink-500",
    }
  ];

  // const ironlandtitleColors = ["bg-green-500", "bg-pink-500"];

  const handleFromCountryChange = (selectedFromCountry) => {
    setSelection((prevSelection) => ({
      ...prevSelection,
      from: selectedFromCountry,
      to: countryOptions[selectedFromCountry][0]?.name || "",
    }));
  };

  const handleToCountryChange = (selectedToCountry) => {
    setSelection((prevSelection) => ({
      ...prevSelection,
      to: selectedToCountry,
    }));
  };

  useEffect(() => {
    // Fetch countries from REST Countries API
    const fetchCountries = async () => {
      try {
        const response = await axios.get("https://restcountries.com/v3.1/all");
        // Extract and sort country names alphabetically
        const countryNames = response.data
          .map((country) => country.name.common)
          .sort((a, b) => a.localeCompare(b));
        setPassportFromCountry(countryNames);
      } catch (error) {
        console.error("Error fetching country data:", error);
      }
    };

    fetchCountries();
  }, []);

  const validate = () => {
    if (selectedCountry !== "Select Country") {
      if (startDate && endDate) {
        setValidation(true);
      } else {
        if (selection.from === "Select Country" && selection.to === "") {
          setIsModalOpenDestination(true);
        } else {
          setIsModalOpenDate(true);
        }
      }
    } else {
      setIsModalOpenPassport(true);
    }
  };

  return (
    <div className="bg-[#00c6ad]">
      <div className=" text-center py-3 mx-2  md:py-8 ">
        <h1 className="text-white text-[26px] md:text-[40px]  font-semibold   md:font-bold mb-8 text-center">
          Let's Get Started.
        </h1>

        <div className="md:flex justify-between px-4">
          <div className="md:flex justify-center items-center w-full">
            <div className="md:flex justify-center md:w-full">
              <div className="md:ms-44 lg:ms-0 flex flex-col items-center lg:flex lg:flex-row lg:justify-between md:w-full lg:w-11/12">
                <div className="flex flex-col justify-left w-8/12 lg:w-2/12 md:w-8/12 mb-8 md:mb-0">
                  <h2 className="mb-4 lg:ms-1 text-lg 2xl:text-[22px] text-white font-normal md:font-medium text-center md:text-left">
                    My Passport is From
                  </h2>
                  <Listbox
                    value={selectedCountry}
                    onChange={setSelectedCountry}
                  >
                    <div className="relative w-full">
                      <Listbox.Button className="relative w-full bg-white text-black py-1.5 pl-3 pr-10 rounded-md shadow-sm">
                        <span className="flex items-center">
                          <span className="w-full text-[#011e41]">
                            {selectedCountry}
                          </span>
                        </span>
                        <FaChevronDown className="absolute inset-y-0 right-0 h-5 w-5 text-[#011e41] mr-2 mt-2" />
                      </Listbox.Button>

                      <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5">
                        {countriesData.countries.map((country) => (
                          <Listbox.Option
                            key={country}
                            value={country}
                            className={({ active, selected }) =>
                              `${
                                active
                                  ? "bg-indigo-600 text-[#011e41]"
                                  : "text-[#011e41]"
                              } relative cursor-default select-none py-2 pl-3 pr-9`
                            }
                          >
                            <span className="w-full text-[#011e41]">
                              {country}
                            </span>
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </div>
                  </Listbox>
                </div>
                <div className="w-8/12 md:w-8/12 lg:w-2/12 text-center mb-8 md:mb-0">
                  <h2 className="mb-4 text-lg 2xl:text-[22px] text-white font-normal md:font-medium font-medium md:text-left text-center">
                    Want to Apply From
                  </h2>
                  <Listbox
                    value={selection.from}
                    onChange={handleFromCountryChange}
                    className="w-full"
                  >
                    <div className="relative w-full">
                      <Listbox.Button className="relative w-full bg-white text-black py-1.5 pl-3 pr-10 rounded-lg shadow-sm">
                        <span className="">
                          <div className="flex ">
                            
                            <span className="w-full text-[#011e41]">
                              {selection.from}
                            </span>
                          </div>
                        </span>
                        <FaChevronDown className="absolute inset-y-0 right-0 h-5 mt-2  w-5 text-[#011e41] mr-2 mt-2" />
                      </Listbox.Button>
                      <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5">
                        {Object.keys(countryOptions).map((region) => (
                          <Listbox.Option
                            key={region}
                            value={region}
                            className={({ active, selected }) =>
                              `${
                                active
                                  ? "bg-indigo-600 text-[#011e41]"
                                  : "text-[#011e41]"
                              }
                    relative cursor-default select-none py-2 pl-3 pr-9`
                            }
                          >
                            <div className="flex">
                            
                              <span className="w-full">{region}</span>
                            </div>
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </div>
                  </Listbox>
                </div>
                <div className="text-center w-8/12 md:w-8/12 lg:w-2/12 mb-8 md:mb-0 ">
                  <h2 className="mb-4 text-lg 2xl:text-[22px] text-white font-normal md:font-medium md:text-left text-center">
                    I am Going to
                  </h2>
                  <Listbox
                    value={selection.to}
                    onChange={handleToCountryChange}
                  >
                    <div className="relative flex">
                      <Listbox.Button className="relative w-full bg-white text-black py-1.5 pl-3 pr-10 rounded-lg shadow-sm">
                        {selection.to && (
                          <div className="flex md:justify-between   ">
                            
                            <span className="w-full text-[#011e41]">
                              {selection.to}
                            </span>
                          </div>
                        )}
                        {!selection.to && (
                          <span className="">{"Select Destination"}</span>
                        )}
                        <FaChevronDown className="absolute inset-y-0 right-0 mt-2 h-5 w-5 text-[#011e41] mr-2 mt-2 " />
                      </Listbox.Button>
                      <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5">
                        {countryOptions[selection.from]?.map((country) => (
                          <Listbox.Option
                            key={country.name}
                            value={country.name}
                            className={({ active, selected }) =>
                              `${
                                active
                                  ? "bg-indigo-600 text-[#011e41]"
                                  : "text-[#011e41]"
                              }
                    relative cursor-default select-none py-2 pl-3 pr-9`
                            }
                          >
                            <div className="flex ">
                             
                              <span className="w-full text-[#011e41]">
                                {country.name}
                              </span>
                            </div>
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </div>
                  </Listbox>
                </div>
                <div className="flex flex-col space-y-3 mb-8 md:mb-0">
                  <h2 className="text-lg 2xl:text-[22px] text-white font-normal md:font-medium md:text-left md:ml-16 lg:ml-0">
                    My Entry and Exit Dates
                  </h2>

                  <div className="flex  w-full">
                    <DatePicker
                      showIcon
                      selected={startDate}
                      onChange={(update) => setDateRange(update)}
                      startDate={startDate}
                      endDate={endDate}
                      selectsRange
                      placeholderText="Start and End Dates"
                      className="w-[220px]  bg-gray-50 border-none text-gray-900 text-sm rounded-lg  focus:ring-blue-500 focus:border-blue-500 py-2.5 ps-2.5 pr-0 dark:bg-gray-700 dark:border-gray-600 placeholder:text-center placeholder:text-[#011e41] placeholder:text-[16px] dark:text-white"
                      icon={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1em"
                          height="1em"
                          viewBox="0 0 48 48"
                        >
                          <mask id="ipSApplication0">
                            <g
                              fill="none"
                              stroke="#fff"
                              strokeLinejoin="round"
                              strokeWidth="4"
                            >
                              <path
                                strokeLinecap="round"
                                d="M40.04 22v20h-32V22"
                              ></path>
                              <path
                                fill="#fff"
                                d="M5.842 13.777C4.312 17.737 7.263 22 11.51 22c3.314 0 6.019-2.686 6.019-6a6 6 0 0 0 6 6h1.018a6 6 0 0 0 6-6c0 3.314 2.706 6 6.02 6c4.248 0 7.201-4.265 5.67-8.228L39.234 6H8.845l-3.003 7.777Z"
                              ></path>
                            </g>
                          </mask>
                          <path
                            fill="currentColor"
                            d="M0 0h48v48H0z"
                            mask="url(#ipSApplication0)"
                          ></path>
                        </svg>
                      }
                    />
                  </div>
                </div>
                <div className="md:flex justify-start md:ms-24 lg:ms-0 mb-8 md:mb-0">
                  <button
                    className="bg-white p-2 px-4 md:px-12 text-[16px] rounded-lg mt-10 text-[#011e41] "
                    onClick={validate}
                  >
                    Get Started
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {validation ? (
        <div>
          {selection.to !== "Select Destination" ? (
            <div className="bg-emerald-50 ">
              {selection.to && selection.from ? (
                <div>
                  {selection.from === "UK" ? (
                    <div className={` `}>
                      <div className="flex justify-center py-3 rounded-lg">
                        <div className="md:flex gap-6 mt-5">
                          {services.map((service, index) => (
                            <div
                              key={index}
                              className={` mt-2 md:mt-0 rounded-3xl shadow-xl backdrop-blur-sm bg-stone-200 hover:bg-white border-t-4 border-transparent`}
                            >
                              <h2
                                className={`text-2xl p-6 rounded-t-lg text-[#00c6ad] font-bold text-center py-4 `}
                              >
                                {service.title}
                              </h2>
                              {index === 1 && (
                                <span className=" text-black font-semibold bg-yellow-200 py-1 px-3 rounded-full absolute top-4 right-2 mt-2 mr-2 text-sm">
                                  Popular
                                </span>
                              )}

                              {index === 2 && (
                                <span className=" text-black font-semibold bg-yellow-200 py-1 px-3 rounded-full absolute top-4 right-0 mt-2 mr-1 text-sm">
                                  Recommended
                                </span>
                              )}

                              <ul className="mb-6 p-6 text-xxs">
                                {service.features.map((feature, idx) => (
                                  <li
                                    key={idx}
                                    className={`flex items-center text-sm space-x-2 mb-2 `}
                                  >
                                    {feature.included ? (
                                      <span>✔️</span>
                                    ) : (
                                      <span>❌</span>
                                    )}
                                    <span>{feature.text}</span>
                                  </li>
                                ))}
                              </ul>

                              <div className="flex justify-center">
                                <div className="text-center w-2/3 rounded-full bg-[#00c6ad] p-1">
                                  <a
                                    href={`https://api.whatsapp.com/send?phone=447553768098&text=Hi%20My%20Passport%20is%20from%20${encodeURIComponent(
                                      selectedCountry
                                    )}%20and%20I%20want%20to%20apply%20for%20a%20visa%20to%20visit%20${encodeURIComponent(
                                      selection.to
                                    )}%20Im%20currently%20a%20resident%20of%20${encodeURIComponent(
                                      selection.from
                                    )}%20My%20Travel%20Dates%20are%20${encodeURIComponent(
                                      dateRange
                                        .map((date) =>
                                          new Date(date).toDateString()
                                        )
                                        .join(" to ")
                                    )}%20Im%20interested%20to%20know%20more%20about%20the%20${encodeURIComponent(
                                      service.title
                                    )}%20package`}
                                    className="text-xl text-white font-bold"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <p className="text-xl text-white font-bold">
                                      {service.price} / per person
                                    </p>
                                    <p className="text-gray-100 text-sm">
                                      £{service.upfront} payable upfront**
                                    </p>
                                  </a>
                                </div>
                              </div>
                              <div className={`h-6 rounded-b-lg mt-1 `}></div>
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="text-center  flex justify-center  mt-8 pb-2">
                        <div className="md:w-full w-10/12">
                          <p className="text-sm text-gray-600">
                            *Appointment and visa fee are not included.
                            <br />
                            **Upfront fee is NON-REFUNDABLE if an appointment
                            can't be booked on time due to no new slots or
                            cancellations.
                            <br />A refundable add-on can be made to any of the
                            above packages for £25 per person to make the
                            upfront fee refundable.
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : selection.from === "Ireland" ? (
                    <div className=" mt-2  '">
                      <div className="flex justify-center py-3 rounded-lg">
                        <div className="md:flex gap-6">
                          {ironlandservices.map((service, index) => (
                            <div
                              key={index}
                              className={`mt-2 md:mt-0 relative rounded-3xl shadow-xl backdrop-blur-sm bg-stone-200 hover:bg-white border-t-4 border-transparent
                          }`}
                            >
                              {/* {service.popular && (
                                <span className="text-black font-bold py-1 px-3 rounded-full absolute top-4 right-0 mt-2 mr-2 text-xs bg-yellow-200">
                                  Recommended
                                </span>
                              )} */}
                              {index === 1 && (
                                <span className=" text-black font-semibold bg-yellow-200 py-1 px-3 rounded-full absolute top-4 right-2 mt-2 mr-2 text-sm">
                                  Popular
                                </span>
                              )}

                              {index === 2 && (
                                <span className=" text-black font-semibold bg-yellow-200 py-1 px-3 rounded-full absolute top-4 right-0 mt-2 mr-1 text-sm">
                                  Recommended
                                </span>
                              )}
                              <h2
                                className={`text-2xl p-6 rounded-t-lg text-[#00c6ad] font-bold text-center py-4
                            }`}
                              >
                                {service.title}
                              </h2>

                              <ul className="mb-6 p-6 text-sm">
                                {service.features.map((feature, idx) => (
                                  <li
                                    key={idx}
                                    className={`flex items-center text-sm space-x-2 mb-2 `}
                                  >
                                    {feature.included ? (
                                      <span>✔️</span>
                                    ) : (
                                      <span>❌</span>
                                    )}
                                    <span>{feature.text}</span>
                                  </li>
                                ))}
                              </ul>

                              <div className="flex justify-center">
                                <div className="text-center w-2/3 rounded-full bg-[#00c6ad] p-1">
                                  <a
                                    href={`https://api.whatsapp.com/send?phone=447553768098&text=Hi%20My%20Passport%20is%20from%20${encodeURIComponent(
                                      selectedCountry
                                    )}%20and%20I%20want%20to%20apply%20for%20a%20visa%20to%20visit%20${encodeURIComponent(
                                      selection.to
                                    )}%20Im%20currently%20a%20resident%20of%20${encodeURIComponent(
                                      selection.from
                                    )}%20My%20Travel%20Dates%20are%20${encodeURIComponent(
                                      dateRange
                                        .map((date) =>
                                          new Date(date).toDateString()
                                        )
                                        .join(" to ")
                                    )}%20Im%20interested%20to%20know%20more%20about%20the%20${encodeURIComponent(
                                      service.title
                                    )}%20package`}
                                    className="text-xl text-white font-bold"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <p className="text-xl text-white font-bold">
                                      {service.price} / per person
                                    </p>
                                    <p className="text-gray-100 text-sm">
                                      £{service.upfront} payable upfront**
                                    </p>
                                  </a>
                                </div>
                              </div>
                              <div
                                className={`h-6 rounded-b-lg mt-1 
                            }`}
                              ></div>
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="text-center  flex justify-center  mt-8 pb-2">
                        <div className="md:w-full w-10/12">
                          <p className="text-sm text-gray-600">
                            *Appointment and visa fee are not included.
                            <br />
                            **Upfront fee is NON-REFUNDABLE if an appointment
                            can't be booked on time due to no new slots or
                            cancellations.
                            <br />A refundable add-on can be made to any of the
                            above packages for €25 per person to make the
                            upfront fee refundable.
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              ) : (
                ""
              )}
            </div>
          ) : null}
        </div>
      ) : null}

      <SelectPopup
        isOpen={isModalOpenDate}
        comment={"Please select Start and End Date"}
        onClose={() => setIsModalOpenDate(false)}
      />
      <SelectPopup
        isOpen={isModalOpenPassport}
        comment={"Please select Your Passport Country"}
        onClose={() => setIsModalOpenPassport(false)}
      />
      <SelectPopup
        isOpen={isModalOpenDestination}
        comment={"Please select Your Start and End Destination "}
        onClose={() => setIsModalOpenDestination(false)}
      />
    </div>
  );
};

export default PricingSection;
